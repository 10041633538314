import React from 'react'
import { PageProps } from 'gatsby'

import Button from '../components/Button/Button'
import Seo from '../components/Seo/Seo'

export default function NotFoundPage(props: PageProps): JSX.Element {
  return (
    <>
      <Seo title='404' />
      <section
        className='bg-slate py-24 md:py-48'
        id='features'
      >
        <div className='max-w-6xl mx-auto px-4 space-y-8'>
          <h1 className='text-4xl md:text-6xl tracking-tighter'>404</h1>
          <h2>Page not found</h2>
          <div>
            <Button to='/'>Back to the Homepage</Button>
          </div>
        </div>
      </section>
    </>
  )
}
